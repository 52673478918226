<template>
  <div class="style-signature">
      <div class="style-signature-field">
        <h2>כותרת</h2>
        <el-input v-model="current_element.header.content" placeholder="כותרת" />
      </div>
      <div class="style-inputs-field">
          <h2>מיקום כותרת</h2>
          <div class="headers_options">
               <el-button @click="current_element.header.header_position='right'" type="primary">ימין</el-button>
               <el-button @click="current_element.header.header_position='center'" type="primary">מרכז</el-button>
               <el-button @click="current_element.header.header_position='left'" type="primary">שמאל</el-button>
          </div>
      </div>
      <div class="style-inputs-field">
          <h2>עיצוב כותרת</h2>
          <div class="headers_options">
               <el-button size="small" @click="current_element.header.header_bold=='unset'?current_element.header.header_bold='bold':current_element.header.header_bold='unset'" round type="danger">
                   <i class="material-icons">format_bold</i>
               </el-button>
               <el-button size="small" @click="current_element.header.header_decoration=='none'?current_element.header.header_decoration='underline':current_element.header.header_decoration='none'" round type="success">
                    <i class="material-icons">format_underlined</i>
               </el-button>
               <el-button size="small" @click="current_element.header.header_style=='normal'?current_element.header.header_style='italic':current_element.header.header_style='normal'" round type="warning">
                   <i class="material-icons">format_italic</i>
               </el-button>
          </div>
      </div>
       <div class="style-inputs-field-rows">
          <div class="col-3">
            <h2>גודל כותרת</h2>
            <el-input-number style="width:100%" v-model="current_element.header.header_size" :min="1" :max="100" />
          </div>
          <div class="col-3">
            <h2>צבע כותרת</h2>
            <el-input type="color" v-model="current_element.header.header_color" />
          </div>
          <div class="col-3">
            <h2>שדה חובה?</h2>
             <el-switch
                    v-model="current_element.mandatory"
                    class="ml-2"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
            />
          </div>
      </div>
      <div class="style-signature-field">
        <h2>רוחב השדה</h2>
        <el-input-number style="width:100%;" v-model="current_element.width" :min="100" :max="1000" />
      </div>
        <div class="style-inputs-field">
          <h2>מיקום השדה</h2>
          <div class="headers_options">
               <el-button @click="current_element.position='right'" type="primary">ימין</el-button>
               <el-button @click="current_element.position='center'" type="primary">מרכז</el-button>
               <el-button @click="current_element.position='left'" type="primary">שמאל</el-button>
          </div>
      </div>
  </div>
</template>

<script>
import {current_element} from '../../../../../../../Scripts/Forms_builder'
export default {
    setup(){

        return{current_element}
    }
}
</script>

<style scoped>
    .style-signature{
        width: 100%;
        height: 100%;
        padding: 5px;
    }
    .style-signature-field{
        margin-bottom: 5px;
        width: 100%;
        height: fit-content;
    }
    .headers_options{
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
    }
    .style-inputs-field-rows{
        margin-bottom: 5px;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
    }
    .col-3{
        width: 30%;
        height: fit-content;
    }
    .col-2{
        width: 49%;
        height: fit-content;
    }
</style>