<template>
  <div class="style-buttons">
       <div class="style-buttons-field">
           <h2>בחירת כפתור</h2>
           <div class="style-buttons-list">
            <el-button @click="current_element.type='primary'" type="primary">כחול</el-button>
            <el-button @click="current_element.type='success'" type="success">ירוק</el-button>
            <el-button @click="current_element.type='info'" type="info">אפור</el-button>
            <el-button @click="current_element.type='warning'" type="warning">צהוב</el-button>
            <el-button @click="current_element.type='danger'" type="danger">אדום</el-button>
           </div>
       </div>
       <div class="style-buttons-field">
           <h2>שם הכפתור</h2>
           <el-input v-model="current_element.data" placeholder="שם הכפתור" />
       </div>
       <div class="style-buttons-field">
           <h2>רוחב הכפתור</h2>
           <el-input-number style="width:100%;" v-model="current_element.width" :min="10" :max="100" />
       </div>
       <div class="style-buttons-field">
           <h2>גובה הכפתור</h2>
           <el-input-number style="width:100%;" v-model="current_element.size" :min="15" :max="100" />
       </div>
       <div class="style-inputs-field">
          <h2>מיקום הכפתור</h2>
          <div class="headers_options">
               <el-button @click="current_element.position='right'" type="primary">ימין</el-button>
               <el-button @click="current_element.position='center'" type="primary">מרכז</el-button>
               <el-button @click="current_element.position='left'" type="primary">שמאל</el-button>
          </div>
      </div>
  </div>
</template>

<script>

import {current_element} from '../../../../../../../Scripts/Forms_builder'
export default {
    setup(){

        
        return{current_element}
    }
}
</script>

<style scoped>
    .style-buttons{
        width: 100%;
        height: 100%;
    }
    .style-buttons-field{
        margin-bottom: 5px;
        width: 100%;
        height: fit-content;
    }
    .style-buttons-list{
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .headers_options{
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
    }
</style>