import {projectFirestore,firebase} from '../../../../firebase/config'

export const save_position_data_in_db = async(data)=>{
    let doc_id = await get_pos_id()
    doc_id = doc_id.toString()
    await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x').collection('Positions')
    .doc(doc_id).set({
        ...data,
        uid:doc_id
    })
    await increment_pos_id()
}
export const update_position_data_in_db = async(data)=>{
    await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x').collection('Positions')
    .doc(data.uid).set(data,{merge:true})
}

const increment_pos_id = async()=>{
    await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x').update({
        pos_inc: firebase.firestore.FieldValue.increment(1)
    })
}

const get_pos_id = async()=>{
    const doc = await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x').get()
    return doc.data().pos_inc
}

export const get_all_positions_from_db = async()=>{
    const docs = await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x')
    .collection('Positions').orderBy('created_at','desc').get()
    return docs.docs.map(doc=>doc.data())
}

export const delete_position_from_db = async(pos_uid)=>{
    const docs = await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x')
    .collection('Positions').doc(pos_uid).delete()
}

