<template>
  <div class="position-main">
      <div class="position-main-header">
          <button @click="state='NewPosition'; selected_pos=null;" class="new-btn">חדש</button>
          <button style="margin-right:5px;" @click="state='ManagePositions'" class="option-btn">תפקידים</button>
          <button style="margin-right:5px;" @click="state='FormsMain'" class="option-btn">טפסים</button>
      </div>
      <div class="position-main-content">
        <component 
            @edit="handle_edit_pos" :positions="positions" 
            @new_pos="handle_add_new_pos" @delete="handle_delete_pos"
            @after_edit="handle_update_pos" :pos_edit="selected_pos" :is="state" 
        />
      </div>

     
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import NewPosition from './NewPosition.vue'
import ManagePositions from './ManagePositions.vue'
import FormsMain from './Forms/FormsMain.vue'
import {get_all_positions_from_db} from '../../../Scripts/Positions'


export default {
    components:{NewPosition,ManagePositions,FormsMain},
    setup(){
        const selected_pos = ref(null)
        const positions = ref([])
        const state = ref(null)

        const handle_edit_pos = (pos)=>{
            selected_pos.value = pos
            state.value="NewPosition"
        }

        const handle_update_pos = (pos)=>{
            state.value = 'ManagePositions'
            const index = positions.value.findIndex(_pos=>_pos.uid==pos.uid)
            if(index!=-1){
                positions.value[index] = {
                   created_at:positions.value[index].created_at,
                   ...pos
                }
            }
            selected_pos.value = null
        }

        const handle_add_new_pos = async()=>{
            positions.value = await get_all_positions_from_db()
            state.value = 'ManagePositions'
        }

        const handle_delete_pos = (pos_uid)=>{
            const index = positions.value.findIndex(pos=>pos.uid==pos_uid)
            if(index!=-1){
                positions.value.splice(index,1)
            }
        }

        const init = async()=>{
            positions.value = await get_all_positions_from_db()
        }

        init()
        return{
            state,
            positions,
            handle_edit_pos,
            handle_delete_pos,
            handle_add_new_pos,
            handle_update_pos,
            selected_pos,
        }
    }
}
</script>

<style scopd>
    .position-main{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .preview-form-build{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 19;
        background: #fff;
    }
    .preview-form-build-wrapper{
        width: 100%;
        max-width: 1200px;
        height: fit-content;
        margin: 0 auto;
        background: whitesmoke;
    }
    .preview-form-build-exit{
        position: absolute;
        width: 30px;
        height: 30px;
        background: crimson;
        color: #fff;
        border-radius: 50%;
        top: 5px;
        left: 5px;
        z-index: 20;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        cursor: pointer;
    }
    .position-main-header{
        width: 100%;
        height: 60px;
        background: #283046;
        border-radius: 5px;
        display: flex;
        align-items: center;
    }
    .option-btn,.new-btn{
        width: 100px;
        height: 80%;
        border: 0;
        border-radius: 5px;
        background: #28c76f;
        color: #fff;
        cursor: pointer;
        font-size: 18px;
    }
    .option-btn{
        background: #7367f0;
    }
    .position-main-content{
        width: 100%;
        height: calc(100% - 60px);
        overflow-y: auto;
    }

    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

</style>