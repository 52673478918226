<template>
  <div class="new-form">
      <div class="new-form-options">
          <BuilderOptions />
      </div>
      <div class="new-form-preview">
          <BuilderPreview />
      </div>
  </div>
</template>

<script>
import BuilderOptions from './form_builder/BuilderOptions.vue'
import BuilderPreview from './form_builder/BuilderPreview.vue'
export default {
    components:{BuilderOptions,BuilderPreview},
    setup(){

        return{}
    }
}
</script>

<style scoped>
    .new-form{
        width: 100%;
        height: 100%;
        background: whitesmoke;
        display: flex;
    }
    .new-form-options{
        width: 30%;
        height: 100%;
    }
    .new-form-preview{
        width: 70%;
        height: 100%;
        padding-bottom:10px;
    }
</style>