<template>
  <div class="human-resources">
      <div class="human-resources-menu">
           <div @click="state='PositionsMain'" class="option">
                <i class="material-icons">supervised_user_circle</i>
                <p>תפקידים</p>
            </div>
           <div class="option">
                <i class="material-icons">supervised_user_circle</i>
                <p>שרשרת קליטה</p>
            </div>
      </div>
      <div class="human-resources-main">
            <component :is="state"/>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import PositionsMain from '../Admin/Components/Positions/PositionsMain.vue'

export default {
    components:{PositionsMain},
    setup(){
        const state = ref(null)

        return{state}
    }
}
</script>

<style scoped>
    .human-resources{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 375px calc(100% - 375px);
        grid-template-rows: 100%;
    }
    .human-resources-menu{
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        overflow-y: auto;
        padding: 5px;
    }
    .human-resources-main{
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
    .option{
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 26px;
        background: var(--secondary);
        padding: 0 5px 0 0;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 5px;
    }
    .option:hover p{
        margin-right:25px;
        color: var(--yellow);
    }
    .option i {
        margin-left: 25px;
        color: var(--light);
        font-size: 33px;
    }
    .option p{
        color:var(--light);
        transition: 0.25s;
    }
    @media only screen and (max-width: 800px) {
        .human-resources{
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows:  150px calc(100% - 150px);
        }
    }
</style>